import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SERVICE_LOOKUP } from 'src/environments/global.config';

export interface ServiceMetadata {
	serviceName: string;
	displayName: string;
	serviceEndpoint: {
		frontend?: string;
		backend: string;
	};
	relatedIamPermissions: string[];
	relatedServices: string[];
	requiredServiceAccounts: string[];
	serviceDocumentation: string;
	keywords: string[];
	isInternal: boolean;
	route: string;
}

export interface ServiceMetadataBody {
	displayName: string;
	serviceEndpoint: {
		frontend?: string;
		backend: string;
	};
	relatedIamPermissions: string[];
	relatedServices: string[];
	requiredServiceAccounts: string[];
	serviceDocumentation: string;
	keywords: string[];
	isInternal: boolean;
	route: string;
	overwriteService: 'true' | 'false';
}

@Injectable({
	providedIn: 'root',
})
export class ServiceRegistryService {
	protected readonly api: string = environment.api.serviceRegistry;

	/**
	 * True if `getAvailableServices` was called at least once and succeeded.
	 */
	private fetchedAll: boolean = false;

	/**
	 * Service cache for quick lookup.
	 */
	private services: any = {};
	private availableServices: any = {};

	constructor(private readonly http: HttpClient) {
		const value = SERVICE_LOOKUP('serviceRegistry');
		if (value != null && value.endpoint !== this.api) {
			this.api = value.endpoint;
		}
	}

	/**
	 * Get all available services and their permissions
	 * @returns A list of service objects including the serviceName and related_iam_permissions
	 */
	public async getAvailableServices(forceRefresh?: boolean): Promise<ServiceMetadata[]> {
		if (this.fetchedAll && !forceRefresh) {
			return this.availableServices;
		}

		const services: ServiceMetadata[] = await this.http.get<ServiceMetadata[]>(this.api + '/v1/Services').toPromise();

		services.forEach((service) => (this.services[service.serviceName] = service));
		this.availableServices = services;
		this.fetchedAll = true;

		return services;
	}

	/**
	 * Describe a single service only
	 * @param service The service that you want to describe
	 * @param forceRefresh ignore cache and force fresh pull from backend
	 * @returns
	 */
	public async describeService(service: string, forceRefresh: boolean = false): Promise<ServiceMetadata> {
		if (!!this.services[service] && !forceRefresh) {
			return this.services[service];
		}

		const serviceInfo = await this.http.get<ServiceMetadata>(this.api + '/v1/Services/' + service).toPromise();
		this.services[service] = serviceInfo;

		return serviceInfo;
	}

	/**
	 * Update a single service
	 * @param service The name of the service that you want to describe
	 * @param body The service object
	 * @returns
	 */
	public async updateService(service: string, body: ServiceMetadataBody): Promise<any> {
		return this.http.post<ServiceMetadata>(this.api + '/v1/Services/' + service, body).toPromise();
	}

	public async deleteService(service: string): Promise<any> {
		return this.http.delete<any>(this.api + '/v1/Services/' + service).toPromise();
	}
}
