import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	public darkMode: boolean = false;

	public checkDarkMode() {
		let prefs = {};

		if (localStorage.getItem('preferences')) {
			prefs = JSON.parse(localStorage.getItem('preferences')!);

			if ('darkMode' in prefs && prefs.darkMode) {
				this.toggleDarkTheme();
			}
		}

		if (!('darkMode' in prefs) && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			this.toggleDarkTheme();
		}
	}

	public async toggleDarkTheme() {
		let prefs = { darkMode: !this.darkMode };

		if (localStorage.getItem('preferences')) {
			prefs = JSON.parse(localStorage.getItem('preferences')!);
			prefs.darkMode = !this.darkMode;
		}

		localStorage.setItem('preferences', JSON.stringify(prefs));
		this.darkMode = document.body.classList.toggle('dark-theme');
	}
}
