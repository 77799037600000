import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

type SubscribeFunction = () => Subscription | undefined;

@Component({
	selector: 'app-schema-based-editor',
	templateUrl: './schema-based-editor.component.html',
	styleUrls: ['./schema-based-editor.component.scss'],
})
export class SchemaBasedEditorComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	@Input()
	public jsonSchema: any;
	@Input()
	public data: any;
	@Input()
	public refs: any;
	@Input()
	public parentFormGroup: FormGroup | undefined;
	@Input()
	public required: boolean = true;
	@Input()
	public root: boolean = false;
	@Input()
	public propertyName: string = 'rootValue';
	@Input()
	// eslint-disable-next-line @typescript-eslint/ban-types
	public removeSelf: Function | undefined;
	@Input()
	public showHeader = true;

	@Input()
	public expandAll = false;
	public expanded = false;

	public lastIndex = 0;

	public type: string | any;
	public values: any[] = [];
	public properties: any[] = [];

	public ownTypeHookSubscription?: Subscription;
	public valueChangeSubscription?: Subscription;
	public changeEditModeSubscription?: Subscription;
	public defaultModeSubscription?: Subscription;
	public jsonModeSubscription?: Subscription;

	@ViewChild('addAdditionalProperty') addAdditionalPropertyForm?: ElementRef;
	public isAddingAdditionalProperty: boolean = false;

	public arrayItems: FormControl = new FormControl([]);
	public formGroup: FormGroup | undefined;
	public additionalProperty: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required, Validators.minLength(1)]),
	});

	public editmode: FormGroup = new FormGroup({
		mode: new FormControl(),
	});

	public editjson: FormGroup = new FormGroup({
		text: new FormControl(),
	});

	public editText: string = '';
	public currentMode = 'default';

	public ownType: FormGroup = new FormGroup({
		type: new FormControl(),
	});

	public supportedTypes: string[] = ['null', 'number', 'string', 'object', 'array', 'boolean'];

	constructor(
		private cdRef: ChangeDetectorRef,
		private fb: UntypedFormBuilder
	) {}

	public async ngOnInit() {
		if (!this.jsonSchema) {
			this.initWithoutGivenSchema();
		} else {
			this.setRefs();
			this.filterSupportedTypes();
			this.setType();
			this.initForm();
			this.createPropertiesList();
			this.initChooseOwnTypeHook();
		}
		this.showHeader = this.propertyName != 'rootValue' && this.showHeader;
	}

	public ngOnDestroy() {
		this.formGroup?.removeControl(this.propertyName || 'rootValue');
		if (this.parentFormGroup?.controls[this.propertyName]) {
			this.parentFormGroup?.removeControl(this.propertyName);
		}
	}

	public async ngAfterViewInit() {
		if (this.jsonSchema) {
			if (this.type === 'array' && this.data) {
				this.populateArrayData();
			}
			if (this.type === 'object' && this.data) {
				this.populateAdditionalProperties();
			}
		}
		this.cdRef.detectChanges();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.expandAll) {
			this.expanded = changes.expandAll.currentValue;
		}
	}

	public filterSupportedTypes() {
		if (!this.jsonSchema.isInsertedBySchemaEditor) {
			this.supportedTypes = this.supportedTypes.filter((type) => this.isOfType(type));
		}
	}

	public isOfType(type: string) {
		return this.jsonSchema && (this.jsonSchema.type === type || this.jsonSchema.type?.includes(type));
	}

	public setType() {
		const multipleTypesAllowed = Array.isArray(this.jsonSchema?.type) && this.jsonSchema?.type.length > 0;
		const isNotTriggeredByResetToDifferentType = !this.jsonSchema.isInsertedBySchemaEditor && this.data;
		if (!this.jsonSchema.type || this.data === null || (isNotTriggeredByResetToDifferentType && multipleTypesAllowed)) {
			this.type = this.inferType();
		} else {
			// take last one that matches
			this.supportedTypes.forEach((type) => {
				if (this.isOfType(type)) {
					this.type = type;
				}
			});
		}
	}

	public setRefs() {
		if (!this.refs) {
			const schemaRef = this.jsonSchema['$defs'];
			this.refs = schemaRef || {};
		}
		if (this.jsonSchema['$ref']) {
			const refName = this.jsonSchema['$ref'].split('$defs/')[1];
			this.jsonSchema = this.refs[refName];
		}
	}

	public initForm() {
		if (this.root) {
			this.formGroup = this.fb.group({});
			this.initEditorHooks();
			this.initSimpleValueInput();
		}
		if (this.parentFormGroup) {
			this.ownType.setValue({ type: this.type });
			if (this.type === 'object') {
				this.formGroup = this.fb.group({});
				this.parentFormGroup.addControl(this.propertyName, this.formGroup);
			} else if (this.type === 'array') {
				this.formGroup = this.fb.group({});
				this.initSubscription(this.valueChangeSubscription, () => {
					return this.formGroup?.valueChanges.subscribe(() => this.extractArrayItems());
				});
				this.parentFormGroup.addControl(this.propertyName, this.arrayItems);
			} else {
				this.formGroup = this.parentFormGroup;
				this.initSimpleValueInput();
			}
		}
		this.expanded = this.expandAll;
	}

	public initSimpleValueInput() {
		if (this.type != 'object' && this.type != 'array') {
			const initValue = this.type === 'null' ? null : this.data;
			this.formGroup?.addControl(this.propertyName, new FormControl(initValue));
		}
	}

	public initSubscription(field: Subscription | undefined, initFunction: SubscribeFunction) {
		if (!field) {
			field = initFunction();
		}
	}

	public async setText(text: string) {
		if (this.editText != text) {
			this.editText = text;
			this.editjson.setValue({
				text: text,
			});
			this.refresh();
		}
	}

	public initChooseOwnTypeHook() {
		this.initSubscription(this.ownTypeHookSubscription, () => {
			return this.ownType.valueChanges.subscribe(() => {
				if (!this.jsonSchema) {
					this.jsonSchema = {};
				}
				/**
				 * sort the supportedTypes array to make chosen type last
				 * after refresh this will cause setType() method to update this.type
				 * setting this.type directly will not work, because refresh triggers
				 * the complete init sequence and this.type will be overwritten
				 *  */
				this.jsonSchema.type = this.supportedTypes.sort((a, b) => {
					const type = this.ownType.value.type;
					return a === type ? 1 : b === type ? -1 : 0;
				});
				if (this.type != this.ownType.value.type) {
					this.data = undefined;
					this.refresh();
				}
			});
		});
	}

	public initEditorHooks() {
		if (this.root) {
			this.editmode.setValue({ mode: this.currentMode });
			this.initSubscription(this.changeEditModeSubscription, () => {
				return this.editmode.valueChanges.pipe(debounceTime(300)).subscribe((change) => this.updateEditors(change));
			});
			this.initSubscription(this.defaultModeSubscription, () => {
				return this.formGroup?.valueChanges.pipe(debounceTime(300)).subscribe((change) => this.defaultModeHook(change));
			});
			this.initSubscription(this.jsonModeSubscription, () => {
				return this.editjson.valueChanges.pipe(debounceTime(300)).subscribe((change) => this.jsonModeHook(change));
			});
		}
	}

	public updateEditors(change: any) {
		if (this.currentMode != change.mode) {
			this.currentMode = change.mode;

			if (this.currentMode === 'default') {
				this.refresh();
			}
			if (this.currentMode === 'json') {
				this.editjson.setValue({ text: this.editText });
			}
		}
	}

	public defaultModeHook(change: any) {
		if (this.currentMode === 'default') {
			switch (this.type) {
				case 'object':
					this.editText = JSON.stringify(change, null, 4);
					break;
				case 'array':
					this.extractArrayItems();
					this.editText = JSON.stringify(this.arrayItems.value, null, 4);
					break;
				default:
					this.editText = JSON.stringify(this.formGroup?.controls.rootValue.value, null, 4);
			}
		}
	}

	public jsonModeHook(change: any) {
		if (this.currentMode === 'json') {
			this.editText = change.text;
		}
	}

	public initWithoutGivenSchema() {
		if (!this.jsonSchema) {
			this.jsonSchema = {
				isInsertedBySchemaEditor: true,
				type: this.inferType(),
			};
			this.refresh();
		}
	}

	public inferType() {
		let type;
		if (Array.isArray(this.data)) {
			type = 'array';
		} else if (this.data === null) {
			type = 'null';
		} else if (this.data != undefined) {
			type = typeof this.data;
		} else {
			type = 'object';
		}
		return type;
	}

	public async refresh() {
		if (this.root && this.editText) {
			this.data = JSON.parse(this.editText);
		}
		this.ngOnDestroy();
		await this.ngOnInit();
		await this.ngAfterViewInit();
	}

	public createPropertiesList() {
		if (this.isOfType('object')) {
			this.properties = this.toList(this.jsonSchema.properties);
		}
	}

	public getProperty(name: string) {
		if (this.data && typeof this.data === 'object') {
			return this.data[name];
		}
	}

	public getItem(index: number) {
		const arrayIndex = index - 1;
		if (Array.isArray(this.data) && arrayIndex < this.data.length) {
			return this.data[arrayIndex];
		}
	}

	public populateArrayData() {
		if (Array.isArray(this.data)) {
			this.values = [];
			this.lastIndex = 0;
			this.data.forEach(() => {
				this.addValue(true);
			});
		}
	}

	public getSubSchemaType(subSchema: any): string | undefined {
		if (subSchema['$ref'] && this.refs) {
			const refName = subSchema['$ref'].split('$defs/')[1];
			subSchema = this.refs[refName];
		}

		switch (subSchema.type.constructor.name) {
			case 'String':
				return subSchema.type;
			case 'Array':
				return subSchema.type[0];
		}
		return undefined;
	}

	public populateAdditionalProperties() {
		const propertyNames = this.properties.map((prop) => {
			return prop.name;
		});
		if (this.data && typeof this.data === 'object') {
			this.values = [];
			const newValues: any[] = [];
			Object.keys(this.data)
				.filter((key) => !propertyNames.includes(key))
				.forEach((key: string) => {
					newValues.push({
						index: key,
						value: this.getProperty(key),
					});
				});
			this.values = newValues;
		}
	}

	public extractArrayItems() {
		this.arrayItems.setValue(Object.values(this.formGroup?.value || {}));
	}

	public toList(obj: any) {
		if (obj) {
			return Object.keys(obj).map((key) => {
				return {
					name: key,
					sub_schema: obj[key],
					value: this.getProperty(key),
				};
			});
		}
		return [];
	}

	public addValue(index: any = undefined) {
		this.lastIndex = this.lastIndex + 1;
		const name = index ? this.lastIndex : this.additionalProperty.value.name;

		const value = index ? this.getItem(name) : this.getProperty(name);

		this.values.push({
			index: name,
			value: value,
		});
		this.additionalProperty.setValue({ name: '' });
	}

	public removeValue(value: any) {
		return () => {
			this.values = this.values.filter((v) => v.index != value.index);
		};
	}

	public display(value: any) {
		return JSON.stringify(value, null, 4);
	}

	public openAdditionalPropertyEdit() {
		this.expanded = true;
		this.isAddingAdditionalProperty = true;
		this.cdRef.detectChanges();
		this.addAdditionalPropertyForm?.nativeElement.scrollIntoView();
		this.addAdditionalPropertyForm?.nativeElement.focus();
	}

	public toggleExpand() {
		this.expanded = !this.expanded;
	}

	public toggleExpandAll() {
		this.expandAll = !this.expandAll;
		if (this.expandAll) {
			this.expanded = true;
		}
		if (!this.expandAll && !this.root) {
			this.expanded = false;
		}
	}
}
