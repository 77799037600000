<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    @if (node.level === 0) {
      <button mat-icon-button disabled></button>
    }
    @if (node.externalLink) {
      <a [href]="node.externalLink" target="_blank" class="custom-link">{{ node.displayName }}</a>
    }
    @if (!node.externalLink && !node.children) {
      <span [routerLink]="node.uipath" style="cursor: pointer; text-decoration: none">{{
        node.displayName
      }}</span>
    }
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding class="expandable-tree-node">
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    <span style="font-weight: 600; cursor: pointer" matTreeNodeToggle>{{ node.name }}</span>
  </mat-tree-node>
</mat-tree>
