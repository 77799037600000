<mat-dialog-title class="mb1 p0" fxLayout="row" fxLayoutAlign="space-between center">
	<p class="h2" [style.margin]="'0'">Service Backend Endpoint Editor</p>
	<button mat-icon-button mat-dialog-close>
		<mat-icon>close</mat-icon>
	</button>
</mat-dialog-title>
<mat-dialog-content class="mat-typography">
	<table mat-table [dataSource]="endpoints" class="mat-elevation-z2" aria-label="EndpointView">
		<ng-container matColumnDef="serviceName">
			<th mat-header-cell *matHeaderCellDef scope="Service Name">Service Name</th>
			<td mat-cell *matCellDef="let endpoint">{{ endpoint.serviceName }}</td>
		</ng-container>
		<ng-container matColumnDef="endpointValue">
			<th mat-header-cell *matHeaderCellDef scope="Endpoint Value">Endpoint</th>
			<td mat-cell *matCellDef="let endpoint">{{ endpoint.endpoint }}</td>
		</ng-container>
		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef scope="Edit button">Edit</th>
			<td mat-cell *matCellDef="let endpoint" [style.cursor]="'default'">
				<button mat-icon-button (click)="editBackendEndpoint(endpoint.serviceName, endpoint.endpoint)">
					<mat-icon>edit</mat-icon>
				</button>
			</td>
		</ng-container>
		<ng-container matColumnDef="reset">
			<th mat-header-cell *matHeaderCellDef scope="Reset button">Reset</th>
			<td mat-cell *matCellDef="let endpoint" [style.cursor]="'default'">
				<button
					mat-icon-button
					(click)="resetBackendEndpoint(endpoint.serviceName)"
					[disabled]="endpoint.endpoint === environmentEndpoints[endpoint.serviceName]"
				>
					<mat-icon>restore</mat-icon>
				</button>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row matRipple *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</mat-dialog-content>
