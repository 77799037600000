import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ReassumableRoleWildcardDialogComponentData {
	message: string;
}

@Component({
	selector: 'app-reassumable-role-wildcard-dialog',
	templateUrl: './reassumable-role-wildcard-dialog.component.html',
	styleUrls: ['./reassumable-role-wildcard-dialog.component.scss'],
})
export class ReassumableRoleWildcardDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<ReassumableRoleWildcardDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ReassumableRoleWildcardDialogComponentData
	) {}

	cancel(): void {
		this.dialogRef.close(false);
	}

	reassume(): void {
		this.dialogRef.close(true);
	}
}
