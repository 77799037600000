import { AgGridAngular, AngularFrameworkComponentWrapper, AngularFrameworkOverrides } from '@ag-grid-community/angular';
import { ColDef, DataTypeDefinition } from '@ag-grid-community/core';
import { Component, ElementRef, HostBinding, OnInit, Renderer2, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { DEFAULT_TABLE_ROWS } from 'src/environments/global.config';
import { CancomTableLoadingOverlay } from './loading-overlay/cancom-table-loading.component';

@Component({
	selector: 'cancom-table',
	template: '',
	styleUrls: ['./cancom-table.component.scss'],
	providers: [AngularFrameworkOverrides, AngularFrameworkComponentWrapper],
	encapsulation: ViewEncapsulation.None,
})
export class CancomTableComponent extends AgGridAngular implements OnInit {
	@HostBinding('style.min-height') public minHeight = '300px';

	public defaultColumnDefinition: ColDef = {
		sortable: true,
		filter: true,
		resizable: true,
		enableRowGroup: true,
		minWidth: 100,
		flex: 1,
		suppressHeaderFilterButton: true,
	};

	// Add Custom Cell Data Types
	public dataTypeDefinitions: { [cellDataType: string]: DataTypeDefinition<any> } = {
		hyperLink: {
			extendsDataType: 'object',
			baseDataType: 'object',
			valueFormatter: (params) => (params.value == null ? '' : params.value.displayName),
			dataTypeMatcher: (value) => value && !!value.displayName && !!value.link,
		},
	};

	constructor(
		elementDef: ElementRef,
		viewContainerRef: ViewContainerRef,
		angularFrameworkOverrides: AngularFrameworkOverrides,
		angularFrameworkComponentWrapper: AngularFrameworkComponentWrapper,
		private renderer: Renderer2
	) {
		super(elementDef, viewContainerRef, angularFrameworkOverrides, angularFrameworkComponentWrapper);

		// Set default theme and remove overwritten themes
		for (const item of elementDef.nativeElement.classList) {
			if (item.includes('ag-theme-')) {
				console.error('AgTheme provided. Please do not add any of the ag-theme styles.');
				this.renderer.removeClass(elementDef.nativeElement, item);
			}
		}
		this.renderer.addClass(elementDef.nativeElement, 'ag-theme-material');
	}

	ngOnInit(): void {
		// Merge optional Component columndefinitions with our preset
		this.defaultColDef = { ...this.defaultColumnDefinition, ...(this.defaultColDef || {}) };

		// Set default values
		this.paginationPageSize = DEFAULT_TABLE_ROWS;
		this.groupAllowUnbalanced = true;
		this.suppressMenuHide = false;
		this.loadingOverlayComponent = CancomTableLoadingOverlay;

		if (this.rowModelType === 'serverSide') {
			this.cacheBlockSize = DEFAULT_TABLE_ROWS;
		}
	}
}
