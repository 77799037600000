import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';

export interface BreadcrumbSection {
	name: string;
	link?: string;
	queryParams?: Params;
}

interface BreadcrumbSectionClass extends BreadcrumbSection {
	class?: string;
}

@Component({
	selector: 'cancom-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
	private _sections: BreadcrumbSection[] = [];

	@Input()
	public alwaysExpand: boolean = false;

	public shownSections: BreadcrumbSectionClass[] = [];

	constructor() {}

	@Input()
	public get sections(): BreadcrumbSection[] {
		return this._sections;
	}

	public set sections(input: BreadcrumbSection[]) {
		this._sections = input;

		if (this.alwaysExpand) {
			this.shownSections = this._sections;
		} else {
			if (this._sections.length > 4) {
				this.shownSections = [
					this._sections[0],
					{ name: '...', class: 'expand' },
					...this._sections.slice(this._sections.length - 2),
				];
			} else {
				this.shownSections = this._sections;
			}
		}
	}

	public expand(event: any) {
		console.log(event);
		this.shownSections = this._sections;
	}
}
