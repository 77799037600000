<div fxLayout="column" fxLayoutGap="16px">
	<p class="h2">{{ headline }}</p>
	<div>
		{{ description }}
	</div>
	<div fxLayout="column">
		<mat-slide-toggle [(ngModel)]="isChecked" color="primary">
			{{ data.checkboxText ? (isChecked ? data.checkboxText.checked : data.checkboxText.unchecked) : data.name }}
		</mat-slide-toggle>
	</div>
	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
		@if (isUpdating) {
			<mat-spinner [diameter]="32"></mat-spinner>
		}
		<button mat-raised-button (click)="cancel()" [disabled]="isUpdating">Cancel</button>
		<button mat-raised-button color="primary" (click)="update(isChecked)" [disabled]="isUpdating">Update</button>
	</div>
</div>
