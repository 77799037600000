<div class="m2">
	<div>
		<div fxShow fxHide.lt-md="true" fxLayout="row" fxLayoutGap="32px" class="lp-row">
			<div fxFlex>
				<div class="header">{{ data.serviceName }}</div>
				<div [innerHTML]="data.description"></div>
			</div>
			<img fxFlex="33" [src]="data.image.url" [alt]="data.image.title || 'Service Image'" />
		</div>
		<div fxHide fxShow.lt-md="true" fxLayout="column" fxLayoutGap="32px">
			<img [src]="data.image.url" [alt]="data.image.title || 'Service Image'" />
			<div fxFlex>
				<div class="header">{{ data.serviceName }}</div>
				<div [innerHTML]="data.description" class="mb3"></div>
			</div>
		</div>
	</div>
	<div fxLayout="row" fxLayout.lt-md="column" class="lp-row" fxLayoutGap="32px">
		<div fxFlex="50">
			<div class="title">How it works</div>
			<div [innerHTML]="data.explanation"></div>
		</div>
		<div fxFlex="50">
			<div class="title">Pricing & Documents</div>
			<div [innerHTML]="data.pricing"></div>
		</div>
	</div>
</div>
