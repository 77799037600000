import { Component } from '@angular/core';

@Component({
	selector: 'app-create-session-feedback',
	templateUrl: './create-session-feedback.component.html',
	styleUrls: ['./create-session-feedback.component.scss'],
})
export class CreateSessionFeedbackComponent {
	public se: string | undefined;

	constructor() {}
}
