import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

type AdmonitionType = 'info' | 'error' | 'success' | 'warning';

@Component({
	selector: 'admonition-box',
	templateUrl: './admonition.component.html',
	styleUrls: ['./admonition.component.scss'],
	animations: [
		trigger('animation', [
			state('void', style({ opacity: 0, height: 0 })),
			state('*', style({ opacity: 1 })),
			transition(':enter', animate(`400ms ease-out`)),
			transition(':leave', animate(`400ms ease-in`)),
		]),
	],
})
export class AdmonitionComponent {
	@Input() public type: AdmonitionType = 'info';

	@Input() public header: string = '';
	@Input() public content: string = '';
	@Input() public closable: boolean = false;
	@Input() public noPadding: boolean = false;

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() public close = new EventEmitter<void>();

	@HostBinding('@animation') get state(): string {
		return '';
	}

	public closeClick() {
		this.close.emit();
	}
}
