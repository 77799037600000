<mat-form-field [appearance]="appearance" [style.width.%]="'100'" class="pb1">
	<mat-label>{{ tenantLabel }}</mat-label>
	<input
		matInput
		#input
		type="text"
		(click)="$event.stopPropagation()"
		(input)="valueChanged($event)"
		[readonly]="readonly"
		[matAutocomplete]="customerAutofill"
		(blur)="onTouched()"
		[value]="initialValue"
		placeholder="type to search CustomerDB"
	/>
	@if (!hideHint) {
		<mat-hint>Select the tenant that you want to use</mat-hint>
	}
	<mat-autocomplete #customerAutofill="matAutocomplete" [displayWith]="renderOption" (optionSelected)="onOptionSelected($event)">
		<!-- If we're loading -->
		@if (loading) {
			<mat-option disabled class="loading">
				<mat-spinner diameter="35"></mat-spinner>
			</mat-option>
		}
		@if (allowWildcard) {
			<mat-option [value]="wildcardOption" [disabled]="loading">
				{{ wildcardOption.name }}
			</mat-option>
		}
		@for (option of filteredOptions; track $index) {
			<mat-option [value]="option" [disabled]="loading">
				{{ option.name }}
			</mat-option>
		}
	</mat-autocomplete>
</mat-form-field>
