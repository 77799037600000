<ul class="m2 breadcrumb flex">
	@for (bread of shownSections; track $index; let last = $last) {
		<li (click)="expand($event)" (keydown.enter)="expand($event)" tabindex="{{ $index }}">
			@if (bread.link) {
				<a [routerLink]="bread.link" [queryParams]="bread.queryParams">{{ bread.name }}</a>
			}
			@if (!bread.link) {
				{{ bread.name }}
			}
			@if (!last) {
				<mat-icon>keyboard_arrow_right</mat-icon>
			}
		</li>
	}
</ul>
