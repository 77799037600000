import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface TextInputeditorOptions {
	name: string;
	label?: string;
	value?: string;
	createField?: boolean;
	buttonText?: string;
}

@Component({
	selector: 'app-textInputeditor-dialog',
	templateUrl: './textInputeditor-dialog.component.html',
	styleUrls: ['./textInputeditor-dialog.component.scss'],
})
export class TextInputeditorDialogComponent {
	public name: string = 'Text';
	public label: string = 'Text';
	public value: string = '';
	public buttonText: string = 'Update';

	constructor(
		public dialogRef: MatDialogRef<TextInputeditorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: TextInputeditorOptions
	) {
		this.name = data.name;
		if (data.label) {
			this.label = data.label;
		} else {
			this.label = data.name;
		}
		if (data.value) {
			this.value = data.value;
		}
		if (data.createField) {
			this.buttonText = 'Create';
		}
		if (data.buttonText) {
			this.buttonText = data.buttonText;
		}
	}

	@ViewChild('autosize') autosize!: CdkTextareaAutosize;

	public update(text: string) {
		this.dialogRef.close(text ? text : '');
	}

	public cancel() {
		this.dialogRef.close();
	}
}
