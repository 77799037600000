import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	private _currentService: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
	private _currentNavigation: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

	constructor(private router: Router) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this._currentNavigation.next(event.url);
			}
		});
	}

	public get currentService(): Observable<string | undefined> {
		return this._currentService.asObservable();
	}

	public setCurrentService(service: string | undefined): void {
		if (service === this._currentService.value) {
			return;
		}
		this._currentService.next(service);
	}

	public get currentNavigation(): Observable<string | undefined> {
		return this._currentNavigation.asObservable();
	}
}
