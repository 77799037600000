<div class="admonition-box" [ngClass]="['admonition-box-' + type, noPadding ? '' : 'p2']">
	@if (header) {
		<p class="h3">
			{{ header }}
			@if (closable) {
				<button mat-icon-button class="right mr2" (click)="closeClick()">
					<mat-icon>close</mat-icon>
				</button>
			}
		</p>
	}
	<div style="white-space: pre-line">
		{{ content }}
		<ng-content></ng-content>
	</div>
</div>
