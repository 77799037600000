<div fxLayout="column" fxLayoutGap="16px">
	<p class="h2">
		<span>{{ header }}</span>
	</p>
	<div fxLayoutGap="8px">
		<mat-form-field>
			<mat-label>Key</mat-label>
			<input matInput [(ngModel)]="key" required />
		</mat-form-field>
		<mat-form-field>
			<mat-label>Value</mat-label>
			<input matInput [(ngModel)]="value" required />
		</mat-form-field>
	</div>
	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
		<button mat-raised-button matDialogClose>Cancel</button>
		<button mat-raised-button color="primary" (click)="update()" [disabled]="!key || !value">{{ buttonText }}</button>
	</div>
</div>
