import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */

interface TreeNode {
	name: string;
}

interface CategoryNode extends TreeNode {
	resources?: ResourceNode[];
}

interface ResourceNode extends TreeNode {
	displayName: string;
	path?: string;
	externalLink?: string;
	name: string;
}

function isCategory(node: any): node is CategoryNode {
	return !!node.resources;
}

function isResource(node: any): node is ResourceNode {
	return !!node.displayName;
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
	expandable: boolean;
	name: string;
	level: number;
}

@Component({
	selector: 'app-sidepanel',
	templateUrl: './sidepanel.component.html',
	styleUrls: ['./sidepanel.component.scss'],
})
export class SidepanelComponent implements OnChanges {
	@Input() public sidebarContent: any = {
		overview: [],
		sections: [],
	};

	@Input() public expandNavigation: boolean = true;

	public sidenavOpen: boolean = true;

	private _transformer = (node: TreeNode, level: number) => {
		if (isCategory(node)) {
			return {
				expandable: !!node.resources && node.resources.length > 0,
				name: node.name,
				level: level,
			};
		} else if (isResource(node)) {
			return {
				expandable: false,
				level: level,
				uipath: node.path,
				...node,
			};
		}

		return {
			expandable: false,
			level: level,
			...node,
		};
	};

	treeControl = new FlatTreeControl<ExampleFlatNode>(
		(node) => node.level,
		(node) => node.expandable
	);

	treeFlattener = new MatTreeFlattener(
		this._transformer,
		(node) => node.level,
		(node) => node.expandable,
		(node) => {
			if (isCategory(node)) {
				return node.resources;
			}

			return undefined;
		}
	);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly main: AppComponent,
		private readonly http: HttpClient,
		private readonly router: Router
	) {}

	ngOnChanges(): void {
		this.dataSource.data = [
			...(this.sidebarContent.overview || []),
			...(this.sidebarContent.sections || []).map((section: any) => ({
				...section,
				resources: section.resources,
			})),
		];

		if (this.expandNavigation) {
			this.treeControl.expandAll();
		}
	}

	public toggleSidebar() {
		this.sidenavOpen = !this.sidenavOpen;
	}

	public navigate(url: string) {
		this.router.navigateByUrl(`IAM/${url}`);
	}
}
