<mat-sidenav-container fxFlexFill>
	<mat-sidenav-content>
		<div class="navigation">
			<div class="navigation-title">{{ nav.title || selectedService }}</div>
			@if (nav) {
				<div class="navigation-item-container">
					@for (item of nav.overview; track item) {
						@if (!item.disabled) {
							@if (item.isExternal) {
								<a class="navigation-item" [href]="item.path" target="_blank">{{ item.displayName }}</a>
							} @else {
								<div class="navigation-item" [routerLink]="[item.path]" routerLinkActive="active">
									{{ item.displayName }}
								</div>
							}
							<mat-divider class="mb2"></mat-divider>
						}
					}
					@for (item of nav.sections; track item) {
						@if (!item.disabled) {
							<div class="navigation-header">{{ item.name }}</div>
							@for (resource of item.resources; track resource) {
								@if (!resource.disabled) {
									@if (resource.isExternal) {
										<a class="navigation-item" [href]="resource.path" target="_blank">{{ resource.displayName }}</a>
									} @else {
										<div class="navigation-item" [routerLink]="[resource.path]" routerLinkActive="active">
											{{ resource.displayName }}
										</div>
									}
								}
							}
							<mat-divider class="mb2"></mat-divider>
						}
					}
				</div>
			}
		</div>

		<div class="content-wrapper" fxLayout="column">
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-content>

	@if (helpBoxPortal) {
		<mat-sidenav position="end" mode="side" [opened]="helpOpen" [style]="{ width: helpBoxComponent.width, 'border-left': 'none' }">
			<div class="helpbox-container" fxFlex>
				<div class="helpbox-title" fxLayoutAlign="space-between center">
					<span>Help</span>
					<button mat-icon-button (click)="toggleHelp()"><mat-icon>close</mat-icon></button>
				</div>
				@if (!helpboxOverlay) {
					<cdk-virtual-scroll-viewport itemSize="50" fxFlex>
						<div fxLayout="column">
							<ng-container [cdkPortalOutlet]="helpBoxPortal"></ng-container>
						</div>
					</cdk-virtual-scroll-viewport>
				}
				@if (helpboxOverlay) {
					<cdk-virtual-scroll-viewport itemSize="50" fxFlex>
						<div fxLayout="column" [innerHtml]="helpboxOverlay.content"></div>
					</cdk-virtual-scroll-viewport>
				}
			</div>
		</mat-sidenav>
	}
</mat-sidenav-container>
