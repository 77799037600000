import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DateService {
	constructor() {}

	public formatDate(date: string | number | Date): string {
		const baseDate = this.buildBaseDate(date);
		return baseDate.toLocaleDateString();
	}

	public formatDateTime(date: string | number | Date): string {
		const baseDate = this.buildBaseDate(date);
		return baseDate.toLocaleString();
	}

	public formatTime(date: string | number | Date): string {
		const baseDate = this.buildBaseDate(date);
		return baseDate.toLocaleTimeString();
	}

	public getCurrentDate(): Date {
		return new Date();
	}

	private buildBaseDate(date: string | number | Date) {
		return this.isDate(date) ? new Date(date) : new Date(+date);
	}

	private isDate(date: string | number | Date) {
		return new Date(date).toString() !== 'Invalid Date';
	}
}
