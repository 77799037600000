import { ILoadingOverlayAngularComp } from '@ag-grid-community/angular';
import { ILoadingOverlayParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
	selector: 'app-loading-overlay',
	templateUrl: './cancom-table-loading.component.html',
})
export class CancomTableLoadingOverlay implements ILoadingOverlayAngularComp {
	public params?: ILoadingOverlayParams;
	public loadingMessage = 'Loading...';

	agInit(params: ILoadingOverlayParams): void {
		this.params = params;
	}
}
