import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IamAccountService, SubaccountType } from 'src/app/components/iam/services/iam-account.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { RoleType } from '../../../components/iam/services/iam-role.service';

export interface ChangeContextData {
	preselectedTenant?: RoleType;
	assumableRoles: RoleType[];
	subaccountRoles?: string[];
	wildcardRoles: string[];
}

@Component({
	selector: 'app-assumable-role-wildcard-dialog',
	templateUrl: './assumable-role-wildcard-dialog.component.html',
	styleUrls: ['./assumable-role-wildcard-dialog.component.scss'],
})
export class AssumableRoleWildcardDialogComponent implements OnInit {
	public isCancom: boolean = false;
	public isPreselectedTenant: boolean = false;
	public roleType: string = 'managed-role';

	public suggestedSubaccounts: SubaccountType[] = [];
	public assumableRoles?: RoleType[] = undefined;

	public possibleRoles: string[] = [];
	public suggestedRoles: string[] = [];

	public subaccountsEnabled = environment.feature.subaccountsEnabled;

	public contextForm: FormGroup = new FormGroup({
		tenant: new FormControl<string>(''),
		assumableTenant: new FormControl<string>(''),
		subaccount: new FormControl<SubaccountType>({ value: this.accountService.defaultSubaccount, disabled: true }),
		role: new FormControl<string>({ value: '', disabled: true }, [Validators.required]),
	});

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ChangeContextData,
		private dialogRef: MatDialogRef<AssumableRoleWildcardDialogComponent>,
		public readonly userService: UserService,
		public readonly accountService: IamAccountService
	) {
		if (this.data) {
			this.possibleRoles = this.data.wildcardRoles;
			this.assumableRoles = this.data.assumableRoles;

			// Update roles depending on the selected sub-account
			this.contextForm.controls.subaccount.valueChanges.subscribe((value: SubaccountType) => {
				this.updateRolesForSubaccount(value);
			});

			if (this.data.preselectedTenant) {
				this.isPreselectedTenant = true;
				this.contextForm.controls.assumableTenant.disable();
				this.contextForm.controls.assumableTenant.setValue(this.data.preselectedTenant.tenant);
				this.contextForm.controls.subaccount.enable();
				this.contextForm.controls.subaccount.setValue(this.accountService.defaultSubaccount);
				this.contextForm.controls.role.enable();
				this.changeRoleType(this.roleType);
				if (this.subaccountsEnabled) {
					this.setSuggestedSubaccounts(this.data.preselectedTenant);
				}
			} else {
				// Update sub-accounts depending on the selected assumableTenant
				this.contextForm.controls.assumableTenant.valueChanges.subscribe((value) => {
					this.contextForm.controls.subaccount.enable();
					this.contextForm.controls.role.enable();
					this.suggestedSubaccounts = [];
					this.contextForm.controls.subaccount.setValue(this.accountService.defaultSubaccount);
					const tenant = this.data.assumableRoles.find((role) => role.tenant == value);
					if (tenant) {
						this.setSuggestedSubaccounts(tenant);
					}
				});
			}
		}
	}

	ngOnInit() {
		this.userService.tenant.subscribe((data) => {
			this.isCancom = data == 'cancom';

			if (this.isCancom && !this.isPreselectedTenant) {
				this.contextForm.controls.tenant.addValidators(Validators.required);
				this.contextForm.controls.assumableTenant.removeValidators(Validators.required);
			} else {
				this.contextForm.controls.tenant.removeValidators(Validators.required);
				this.contextForm.controls.assumableTenant.addValidators(Validators.required);
			}
		});
	}

	public async customerSelectionFinalized() {
		// Update sub-accounts depending on the selected customer
		this.contextForm.controls.subaccount.setValue(this.accountService.defaultSubaccount);
		this.suggestedSubaccounts = await this.accountService.listSubaccounts(this.contextForm.controls.tenant.value);
		this.contextForm.controls.subaccount.enable();
		this.contextForm.controls.role.enable();
	}

	public async setSuggestedSubaccounts(tenant: RoleType) {
		const subaccounts = await this.accountService.listSubaccounts(tenant.tenant);

		tenant.subaccounts
			.filter((subAcc) => subAcc.id !== 'default')
			.forEach((subAcc) =>
				this.suggestedSubaccounts.push({
					displayName: subaccounts.find((e) => e.subAccount == subAcc.id)?.displayName || 'DEFAULT',
					subAccount: subAcc.id,
					tenant: tenant.tenant,
				})
			);
	}

	private updateRolesForSubaccount(selectedSubaccount: SubaccountType) {
		const tenantId: string = this.contextForm.controls.tenant.value || this.contextForm.controls.assumableTenant.value;
		const rolesForTenant = this.assumableRoles?.find((e) => e.tenant == tenantId);
		this.possibleRoles = rolesForTenant?.subaccounts.find((e) => e.id == selectedSubaccount.subAccount)?.roles || [];
		this.possibleRoles = this.possibleRoles.concat(this.data.wildcardRoles);
		this.changeRoleType(this.roleType);
	}

	public changeRoleType(roleType: string) {
		this.suggestedRoles = [];
		this.contextForm.controls.role.reset();

		for (const role of this.possibleRoles) {
			const splitRole = role.split(':');
			if (splitRole[0] == roleType) {
				this.suggestedRoles.push(splitRole.slice(-1).join(':'));
			}
		}
	}

	public changeContext() {
		const tenant =
			this.isCancom && !this.isPreselectedTenant
				? this.contextForm.controls.tenant.value
				: this.contextForm.controls.assumableTenant.value;
		const subaccount = this.contextForm.value.subaccount.subAccount == 'default' ? '' : this.contextForm.value.subaccount.subAccount;

		try {
			this.userService.assumeRole(`crn:${tenant}:${subaccount}:iam:${this.roleType}:${this.contextForm.value.role}`);
		} catch (e) {
			if (e instanceof HttpErrorResponse) {
				console.log(e);
			}
		}
		this.dialogRef.close();
	}

	public renderOption(option: SubaccountType) {
		return option.subAccount == 'default' ? option.displayName : `${option.displayName} | ${option.subAccount}`;
	}
}
