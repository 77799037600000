import { Component, Input } from '@angular/core';

@Component({
	selector: 'back-button',
	templateUrl: './back-button.component.html',
	styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
	@Input() public route: string | any[] | null | undefined = '..';

	constructor() {}
}
