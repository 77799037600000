<p class="h2">
	{{ title }}
</p>

<div>
	<p style="white-space: pre-line">{{ message }}</p>
	@if (items) {
		<div>
			<ul>
				@for (item of items; track item) {
					<li>{{ item }}</li>
				}
			</ul>
		</div>
	}
</div>

<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
	@if (isProcessing) {
		<mat-spinner [diameter]="24"></mat-spinner>
	}
	<button mat-raised-button [disabled]="isProcessing" (click)="cancel()">Cancel</button>
	<button mat-raised-button [disabled]="isProcessing" color="primary" (click)="confirmAction()">Confirm</button>
</div>
