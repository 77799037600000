import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface BooleditorOptions {
	headline?: string;
	description?: string;
	name: string;
	checkboxText?: {
		checked: string;
		unchecked: string;
	};
	value?: boolean;
	callback?: (value: boolean) => Promise<string | undefined>;
}

@Component({
	selector: 'app-booleditor-dialog',
	templateUrl: './booleditor-dialog.component.html',
	styleUrls: ['./booleditor-dialog.component.scss'],
})
export class BooleditorDialogComponent {
	public isUpdating: boolean = false;
	public error: string = '';
	public headline: string;
	public description?: string;
	public isChecked: boolean = false;
	public callback?: (value: boolean) => Promise<string | undefined>;

	constructor(
		public dialogRef: MatDialogRef<BooleditorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: BooleditorOptions
	) {
		this.headline = data.headline ? data.headline : data.name + ' Editor';
		this.description = data.description;
		this.callback = data.callback;

		if (data.value != undefined) {
			this.isChecked = data.value;
		}
	}

	public async update(value: boolean) {
		this.isUpdating = true;
		let result: any = undefined;

		if (this.callback) {
			try {
				result = await this.callback(value);
			} catch (err) {
				if (err instanceof Error) {
					result = err.message;
				} else if (err instanceof HttpErrorResponse) {
					result = err.error.message;
				} else {
					result = err;
				}
			}
		}

		if (result === undefined) {
			this.close(value);
		} else {
			this.error = result;
		}

		this.isUpdating = false;
	}

	public close(value?: boolean) {
		this.dialogRef.close(value);
	}

	public cancel() {
		this.dialogRef.close();
	}
}
