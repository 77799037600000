import { Component } from '@angular/core';

@Component({
	selector: 'app-ag-btn-renderer',
	templateUrl: './ag-btn-renderer.component.html',
	styleUrls: ['./ag-btn-renderer.component.scss'],
})
export class AgBtnRendererComponent {
	private params: any;

	agInit(params: any): void {
		this.params = params;
	}

	btnClickedHandler() {
		this.params.clicked(this.params.value);
	}
}
