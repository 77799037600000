<p class="h2">Reassume the role</p>

<div>
	<p>{{ data.message }}</p>
	<p>Press No if you do not want to assume the role again.</p>
</div>

<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
	<button mat-raised-button (click)="cancel()">Cancel</button>
	<button mat-raised-button color="primary" (click)="reassume()">Reassume</button>
</div>
