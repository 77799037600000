@if (error) {
	<admonition-box class="mb2" type="error" [content]="error" header="Error" [closable]="true" (close)="error = ''"></admonition-box>
}

<div fxLayout="column" fxLayoutGap="16px">
	<p class="h2">{{ headline }}</p>
	<div>
		{{ description }}
	</div>
	<div fxLayout="column">
		<mat-form-field>
			<mat-label>Test</mat-label>
			<mat-select [formControl]="selectForm" [multiple]="!!data.multiselect">
				@for (option of data.values; track option) {
					<mat-option [value]="option.value">{{ option.name }}</mat-option>
				}
			</mat-select>
		</mat-form-field>
	</div>
	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
		@if (isUpdating) {
			<mat-spinner [diameter]="32"></mat-spinner>
		}
		<button mat-raised-button (click)="cancel()" [disabled]="isUpdating">Cancel</button>
		<button mat-raised-button color="primary" (click)="update()" [disabled]="!selectForm.valid || isUpdating">Update</button>
	</div>
</div>
