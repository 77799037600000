import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationBarComponent, NotificationBarType } from '../shared/notification-bar/notification-bar.component';

@Injectable({
	providedIn: 'root',
})
export class NotificationBarService {
	constructor(private snackbar: MatSnackBar) {}

	public notify(type: NotificationBarType, header: string | undefined, content: string): MatSnackBarRef<NotificationBarComponent> {
		return this.snackbar.openFromComponent(NotificationBarComponent, {
			duration: 5000,
			data: { type, header, content },
			horizontalPosition: 'end',
			verticalPosition: 'top',
			panelClass: 'notificationbar-padding',
		});
	}
}
