import { Component, ElementRef, HostBinding, OnInit } from '@angular/core';

@Component({
	selector: 'skeleton-rect',
	template: ``,
	styles: [
		`
			:host {
				display: block;
				width: var(--skeleton-rect-width);
				height: var(--skeleton-rect-height);
				background: var(--loading-skeleton-background) no-repeat;
			}
		`,
	],
})
export class RectComponent implements OnInit {
	@HostBinding('class') class = 'pulse';

	width?: string;
	height?: string;
	className?: string;

	constructor(private host: ElementRef<HTMLElement>) {}

	ngOnInit() {
		const host = this.host.nativeElement;

		if (this.className) {
			const classesToAdd = this.className.split(' ');
			for (const classToAdd of classesToAdd) {
				host.classList.add(classToAdd);
			}
		}

		host.style.setProperty('--skeleton-rect-width', this.width ?? '100%');
		host.style.setProperty('--skeleton-rect-height', this.height ?? '20px');
	}
}
