<div class="notification-box" [ngClass]="['notification-box-' + type]" fxLayout="row">
	<div class="p2" fxLayoutAlign="center center">
		@if (type === 'info') {
			<mat-icon>info</mat-icon>
		}
		@if (type === 'warning') {
			<mat-icon>warning</mat-icon>
		}
		@if (type === 'error') {
			<mat-icon>error</mat-icon>
		}
		@if (type === 'success') {
			<mat-icon>check_circle</mat-icon>
		}
	</div>
	<div class="notification-content py2" fxFlex fxLayout="column" fxLayoutGap="8px">
		@if (header) {
			<div class="notification-header">{{ header }}</div>
		}
		@if (content) {
			<div>{{ content }}</div>
		}
	</div>
	<div class="pr1 pt1">
		<button mat-icon-button (click)="close()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
</div>
