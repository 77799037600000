@if (error) {
	<admonition-box class="mb2" type="error" [content]="error" header="Error" [closable]="true" (close)="error = ''"></admonition-box>
}

<div fxLayout="column" fxLayoutGap="16px" style="height: 450px">
	<p class="h2">{{ headline }}</p>
	<div>
		{{ description }}
	</div>
	<mat-list fxFlex>
		@for (tenant of tenants; track tenant; let i = $index) {
			<mat-list-item>
				<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px" style="width: 100%">
					<span fxFlex>{{ tenant.name }}</span>
					<button mat-icon-button (click)="deleteTenant(i)" fxFlex="nogrow">
						<mat-icon>clear</mat-icon>
					</button>
				</div>
			</mat-list-item>
		}
	</mat-list>
	<div fxLayout="row" style="width: 100%; height: auto" fxLayoutGap="8px">
		<mcm-customer-select appearance="outline" style="height: 100%" #tenantForm fxFlex></mcm-customer-select>
		<button mat-raised-button color="primary" (click)="addTenant(tenantForm)" style="height: calc(100% - 32px)">Add</button>
	</div>
	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
		@if (isUpdating) {
			<mat-spinner [diameter]="32"></mat-spinner>
		}
		<button mat-raised-button (click)="close(undefined)" [disabled]="isUpdating">Cancel</button>
		<button mat-raised-button color="primary" (click)="update()" [disabled]="isUpdating">Update</button>
	</div>
</div>
