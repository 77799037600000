import { Clipboard } from '@angular/cdk/clipboard';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { KeycloakService } from 'keycloak-angular';
import { CountdownEvent } from 'ngx-countdown';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IamRoleService, RoleType } from './components/iam/services/iam-role.service';
import { IamServiceUserService } from './components/iam/services/iam-service-user.service';
import { CustomerInformation, CustomerdbService } from './components/services/customer-db/services/customer-db.service';
import { ServiceRegistryService } from './components/services/service-registry/services/service-registry.service';
import { AppService } from './services/app.service';
import { NotificationBarService } from './services/notification-bar.service';
import { ThemeService } from './services/theme.service';
import { LastUsedRole, UserService } from './services/user.service';
import { AssumableRoleWildcardDialogComponent } from './shared/dialogs/assumable-role-wildcard-dialog/assumable-role-wildcard-dialog.component';
import { OverwriteBackendEndpointDialogComponent } from './shared/dialogs/overwrite-backend-endpoint-dialog/overwrite-backend-endpoint-dialog.component';
import { ReassumableRoleWildcardDialogComponent } from './shared/dialogs/reassumable-role-wildcard-dialog/reassumable-role-wildcard-dialog.component';
import { OnetimeViewerComponent } from './shared/onetime-viewer/onetime-viewer.component';

export interface UserInfo {
	tenant: string;
	email: string;
	email_verified: boolean;
	family_name: string;
	given_name: string;
	name: string;
	preferred_username: string;
	roles: string[];
	sub: string;
}

export type ServiceType = {
	name: string;
	displayName: string;
	route: string;
};

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	// encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
	public stage: string = environment.stage;
	public supportEMail: string = environment.supportEMail;

	public currentYear: number = new Date().getFullYear();
	public dateDiff: number | undefined = undefined;

	public sidenavOpen: boolean = true;
	public isLoggedIn: boolean = false;
	public canEditEndpoint: boolean = false;

	public crn: string = '';
	public isImpersonating: boolean = false;
	public userInfo: UserInfo | null = null;
	public customers: CustomerInformation[] = [];

	public assumableRoles: RoleType[] = [];
	public wildcardRoles: string[] = [];
	public lastUsedRoles: LastUsedRole[] = [];

	public retrieveServices: any[] = [];
	public selectedService?: string = undefined;
	public dateDiffRole: number | undefined = undefined;
	public dateDiffImpersonation: number | undefined = undefined;

	public services: ServiceType[] = [{ name: 'Dashboard', displayName: 'Dashboard', route: 'dashboard' }];

	public subscriptions: Subscription[] = [];

	constructor(
		private clipboard: Clipboard,
		private keycloak: KeycloakService,
		private notificationBarService: NotificationBarService,
		private readonly customerdbService: CustomerdbService,
		private readonly dialog: MatDialog,
		private readonly roleService: IamRoleService,
		private readonly router: Router,
		private readonly serviceRegistryService: ServiceRegistryService,
		private readonly appService: AppService,
		private readonly serviceUserService: IamServiceUserService,
		public readonly userService: UserService,
		public themeService: ThemeService
	) {
		this.subscriptions.push(
			this.userService.tenantName.subscribe(() => {
				if (localStorage.getItem('lastUsedRoles') !== null) {
					this.lastUsedRoles = JSON.parse(localStorage.getItem('lastUsedRoles')!);

					if (this.lastUsedRoles.some((e) => e.roles.some((r) => r.role === undefined))) {
						console.error('Found Invalid entries for lastUsedRoles. Resetting...');
						localStorage.removeItem('lastUsedRoles');
						this.lastUsedRoles = [];
					}
				}
			}),

			this.appService.currentService.subscribe((currentService) => (this.selectedService = currentService)),

			this.router.events
				.pipe(
					map(() => this.services.find((y) => y.route === this.router.url.split('/')[1])?.displayName),
					distinctUntilChanged()
				)
				.subscribe((x) => this.appService.setCurrentService(x))
		);

		themeService.checkDarkMode();
	}

	public async ngOnInit() {
		if (environment.stage === 'dev') {
			this.canEditEndpoint = true;
		}

		// Bootstrap the userService here
		await this.userService.initialize();
		this.isLoggedIn = await this.userService.isLoggedIn();
		if (this.isLoggedIn) {
			this.userInfo = await this.userService.getUserInfo();
		}

		const crn = await this.userService.getCRN();
		this.checkAssumedRole(crn);
		this.checkImpersonatedUser();
		this.loadAssumableRoles(crn);
		this.loadServicePolicies();
		// await this.searchCustomers('');

		this.userService.sessionToken.subscribe((token) => {
			if (token) {
				const tokenDec = new JwtHelperService().decodeToken(token);
				const currentDate = Math.floor(Date.now() / 1000);

				if (this.userService.isAssumingRole()) {
					this.dateDiffRole = tokenDec.exp - currentDate;
				}

				if (this.userService.impersonatedName) {
					this.dateDiffImpersonation = tokenDec.exp - currentDate;
					this.isImpersonating = true;
				}
			} else {
				this.isImpersonating = false;
			}
		});
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach((x) => x.unsubscribe());
	}

	async loadServicePolicies() {
		try {
			const allAvailableServices = await this.serviceRegistryService.getAvailableServices();
			const serviceArray: ServiceType[] = [];
			allAvailableServices
				.filter((service: any) => service.route)
				.forEach((service) =>
					serviceArray.push({
						name: service.serviceName,
						displayName: service.route == 'coming-soon' ? service.displayName + ' (coming soon)' : service.displayName,
						route: service.route,
					})
				);

			serviceArray.sort((a, b) => a.displayName.localeCompare(b.displayName));

			if (!this.selectedService) {
				this.appService.setCurrentService(serviceArray.find((y) => y.route === this.router.url.split('/')[1])?.displayName);
			}

			this.services.push(...serviceArray);
		} catch (e) {
			console.log(e);
		}
	}

	public login() {
		this.keycloak.login();
	}

	public logout() {
		let redirectPath: string = '';
		if (window.location.hostname === 'localhost') {
			redirectPath = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/login`;
		} else {
			redirectPath = `${window.location.protocol}//${window.location.hostname}/login`;
		}
		this.keycloak.logout(redirectPath);
	}

	public toggleNav(): void {
		this.sidenavOpen = !this.sidenavOpen;
	}

	public openService(service: any): void {
		if (service.route != 'coming-soon') {
			// instead of not doing anything we should navigate to some coming soon component!?
			if (service.isGeneric) {
				this.router.navigate(['gen', service.route]);
			} else if (!!service.external) {
				window.open(service.external, '_blank');
			} else if (!service.isGeneric) {
				this.router.navigate([service.route]);
				this.setValuesOnLocalStorage(service);
			}
		}
	}

	private setValuesOnLocalStorage(service: any) {
		if (service.name !== 'Dashboard') {
			if (localStorage.getItem('recentlyUsedServices') === null) {
				this.retrieveServices = [];
			} else {
				this.retrieveServices = JSON.parse(localStorage.getItem('recentlyUsedServices')!);
			}

			let checkExists: boolean = false;
			for (let i = 0; i < this.retrieveServices.length; i++) {
				if (service.name === this.retrieveServices[i].name && service.displayName === this.retrieveServices[i].displayName) {
					this.retrieveServices.push(this.retrieveServices.splice(i, 1)[0]);
					checkExists = true;
				}
			}
			if (!checkExists) {
				this.retrieveServices.push(service);
			}

			localStorage.setItem('recentlyUsedServices', JSON.stringify(this.retrieveServices));
		}
	}

	async loadAssumableRoles(crn: string) {
		const res = await this.roleService.getAssumableAndWildcardRoles(crn);
		this.assumableRoles = res.assumableRoles;
		this.wildcardRoles = res.wildcardRoles;
	}

	async searchCustomers(customer: string) {
		try {
			this.customers = await this.customerdbService.searchCustomer(customer);
		} catch (e) {
			if (e instanceof HttpErrorResponse) {
				console.log(e);
			}
		}
	}

	public changeContext(preselectedTenant?: RoleType) {
		const subaccounts = preselectedTenant?.subaccounts || [];
		if (subaccounts.length == 1 && subaccounts[0].roles.length == 1 && !!!this.wildcardRoles.length) {
			const roleParts = subaccounts[0].roles[0].split(':');
			const subaccountId = subaccounts[0].id == 'default' ? '' : subaccounts[0].id;
			this.userService.assumeRole(`crn:${preselectedTenant!.tenant}:${subaccountId}:iam:${roleParts[0]}:${roleParts[1]}`);
		} else {
			this.dialog.open(AssumableRoleWildcardDialogComponent, {
				minWidth: '600px',
				data: {
					preselectedTenant,
					assumableRoles: this.assumableRoles,
					wildcardRoles: this.wildcardRoles,
				},
			});
		}
	}

	public assumeRoleSplit(tenant: string, subaccount: string, role: string) {
		this.userService.assumeRole(`crn:${tenant}:${subaccount}:iam:${role}`);
	}

	public checkAssumedRole(crn: string) {
		const currentRole = localStorage.getItem('currentRole');
		if (currentRole != null && currentRole != crn) {
			this.userService.assumeRole(currentRole);
		}
	}

	public checkImpersonatedUser() {
		if (localStorage.getItem('impersonatedUser') !== null) {
			const impersonatedUserData = localStorage.getItem('impersonatedUser')!;
			const tokenDec = new JwtHelperService().decodeToken(impersonatedUserData);
			this.userService.impersonateUser(tokenDec.principalCRN, 'resumed session', impersonatedUserData);
		}
	}

	public removeLastUsedRole(tenant: string, subaccount: string, role: string) {
		this.userService.removeLastUsedRoleFromLocalStorage(tenant, subaccount, role);
		this.lastUsedRoles = JSON.parse(localStorage.getItem('lastUsedRoles')!);
	}

	public profile() {
		this.router.navigate(['my-profile']);
	}

	public copyIdToClipboard() {
		this.clipboard.copy(this.userService.currentTenant!);
		this.notificationBarService.notify('info', undefined, `"${this.userService.currentTenant!}" was copied to the clipboard`);
	}

	public async createSessionToken() {
		const sessionResponse = await this.serviceUserService.createSessionToken(await this.userService.getCRN());

		this.dialog.open(OnetimeViewerComponent, {
			data: {
				header: 'Session Token created successfully',
				alertText: 'Please write down or save your newly generated Session Token. This token will only be valid for 8 hours.',
				secret: sessionResponse.jwt,
			},
			minWidth: '500px',
			autoFocus: false,
		});
	}

	public backendEndpointEditor() {
		try {
			this.dialog.open(OverwriteBackendEndpointDialogComponent, {
				data: {},
				autoFocus: false,
				minWidth: '600px',
			});
		} catch (e) {
			console.log(e);
		}
	}

	public handleEventRole(event: CountdownEvent) {
		if (event.action === 'notify') {
			const minutesLeft = event.left / 1000 / 60;

			this.notificationBarService.notify(
				'info',
				'Info',
				`Your session will expire in ${minutesLeft} ${minutesLeft > 1 ? 'minutes' : 'minute'}.`
			);
		}
		if (event.action === 'done') {
			this.dateDiffRole = 0;
			this.userService.leaveRole();

			this.dialog
				.open(ReassumableRoleWildcardDialogComponent, {
					width: '600px',
					data: { message: 'Your session has expired. You were logged out. Do you want to reassume the role?' },
				})
				.afterClosed()
				.subscribe((result) => {
					if (result) {
						this.assumeRoleSplit(
							this.lastUsedRoles[0].tenant,
							this.lastUsedRoles[0].roles[0].subaccount,
							this.lastUsedRoles[0].roles[0].role
						);
					} else {
						return;
					}
				});
		}
	}

	public handleEventImpersonation(event: CountdownEvent) {
		if (event.action === 'notify') {
			const minutesLeft = event.left / 1000 / 60;

			this.notificationBarService.notify(
				'info',
				'Info',
				`Your session will expire in ${minutesLeft} ${minutesLeft > 1 ? 'minutes' : 'minute'}.`
			);
		}
		if (event.action === 'done') {
			this.userService.stopImpersonating();
			this.isImpersonating = false;
		}
	}
}
