import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SERVICE_LOOKUP } from 'src/environments/global.config';

@Injectable({
	providedIn: 'root',
})
export class IamPolicyService {
	protected readonly api: string = environment.api.iam;

	constructor(private readonly http: HttpClient) {
		const value = SERVICE_LOOKUP('iam');
		if (value != null && value.endpoint !== this.api) {
			this.api = value.endpoint;
		}
	}

	private principalPolicyChange = new BehaviorSubject('*');
	principalPolicyUpdate = this.principalPolicyChange.asObservable();

	updatedPrincipalPoliciy(principal: string) {
		this.principalPolicyChange.next(principal);
	}
	/**
	 * Get all services that the principal has permissions for
	 * @param user The user CRN
	 * @returns A list of service names
	 */
	public async getPrincipalServices(user: string): Promise<string[]> {
		return this.http.get<string[]>(this.api + '/v1/PolicyDocuments/' + user).toPromise();
	}

	public async getActualPrincipalServices(user: string): Promise<string[]> {
		return this.http.get<string[]>(this.api + '/v1/PolicyDocuments/' + user + '/Combined').toPromise();
	}

	/**
	 * Delete specified services from the principal
	 * @param user The user CRN
	 * @param services The services to delete
	 * @returns
	 */
	public async deletePrincipalServices(user: string, services: string[]): Promise<any> {
		const response = await this.http
			.delete<any>(this.api + '/v1/PolicyDocuments/' + user, { body: { services: services } })
			.toPromise();
		this.updatedPrincipalPoliciy(user);
		return response;
	}

	/**
	 * Get permissions that the principal has for the given service
	 * @param user The user
	 * @param service
	 * @returns
	 */
	public async getPrincipalServicePermissions(user: string, service: string): Promise<Map<string, any>> {
		return this.http.get<Map<string, any>>(this.api + '/v1/PolicyDocuments/' + user + '/' + service).toPromise();
	}

	public async getActualPrincipalServicePermissions(user: string, service: string): Promise<any> {
		return this.http.get<any>(this.api + '/v1/PolicyDocuments/' + user + '/' + service + '/Combined').toPromise();
	}

	public async updatePrincipalServicePermissions(
		user: string,
		service: string,
		document: { [permission: string]: string[] }
	): Promise<void> {
		const response = await this.http.put<any>(this.api + '/v1/PolicyDocuments/' + user, { service, policy: document }).toPromise();
		this.updatedPrincipalPoliciy(user);
		return response;
	}

	public async addUserToTrustPolicy(role: string, user: string): Promise<any> {
		return this.http.put<any>(this.api + '/v1/TrustPolicy/' + role, { addPrincipal: user }).toPromise();
	}

	public async removeUserFromTrustPolicy(role: string, user: string): Promise<any> {
		return this.http.put<any>(this.api + '/v1/TrustPolicy/' + role, { remPrincipal: user }).toPromise();
	}

	public async overwriteTrustPolicy(role: string, users: string[]): Promise<any> {
		return this.http.put<any>(this.api + '/v1/TrustPolicy/' + role, { trustPolicy: users }).toPromise();
	}
}
