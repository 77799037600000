<div fxLayout="column">
	<p class="h3" mat-dialog-title>{{ header }}</p>

	<div mat-dialog-content fxLayout="column" fxLayoutGap="8px">
		<admonition-box type="info" [content]="alertText"></admonition-box>
		<mat-form-field appearance="outline">
			<textarea
				matInput
				cdkTextareaAutosize
				#autosize="cdkTextareaAutosize"
				cdkAutosizeMinRows="2"
				cdkAutosizeMaxRows="10"
				disabled
				>{{ data.secret }}</textarea
			>
		</mat-form-field>

		<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
			<button mat-raised-button color="primary" (click)="copyToClipboard()">
				<div fxLayoutGap="8px" fxLayoutAlign="start center">
					<mat-icon>content_copy</mat-icon>
					<span>Copy to Clipboard</span>
				</div>
			</button>
			<button mat-raised-button mat-dialog-close>Close</button>
		</div>
	</div>
</div>
