import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Inject, Input, Optional } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { ServiceRegistryService } from 'src/app/components/services/service-registry/services/service-registry.service';
import { AppService } from 'src/app/services/app.service';
import { UserService } from 'src/app/services/user.service';

export const HELPBOX_COMPONENT = Symbol();

export interface HelpboxOverlay {
	title: string;
	content: string;
}

export interface ServiceInformation {
	name: string;
	displayName: string;
	path: string;
	isExternal?: boolean;
	disabled?: boolean;
}

export interface NavigationInformation {
	title?: string;
	overview: ServiceInformation[];
	sections: {
		name: string;
		resources: ServiceInformation[];
		disabled?: boolean;
	}[];
}

@Component({
	selector: 'mcm-default-service-layout[nav]',
	templateUrl: './default-service-layout.component.html',
	styleUrls: ['./default-service-layout.component.scss'],
})
export class DefaultServiceLayoutComponent {
	@Input() nav!: NavigationInformation;

	public selectedService?: string = undefined;
	public selectedServiceEnabled: boolean = true;

	public helpboxOverlay?: HelpboxOverlay;
	public helpOpen: boolean = false;
	public helpBoxPortal?: ComponentPortal<any> = undefined;

	constructor(
		@Optional() @Inject(HELPBOX_COMPONENT) public helpBoxComponent: { component: any; width: string },
		private appService: AppService,
		private userService: UserService,
		private serviceRegistryService: ServiceRegistryService,
		private router: Router
	) {
		this.appService.currentService.pipe(takeUntilDestroyed()).subscribe(async (currentService) => {
			if (currentService) {
				this.selectedService = currentService;
				const service = (await this.serviceRegistryService.getAvailableServices()).find(
					(service) => service.displayName === currentService
				);
				if (service?.serviceName) {
					this.selectedServiceEnabled = await this.userService.getServiceState(service?.serviceName);
				}
			}
		});

		if (this.helpBoxComponent) {
			this.helpBoxPortal = new ComponentPortal(helpBoxComponent.component);
		}

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.helpOpen = false;
			}
		});
	}

	public updateHelpBox(title: string, description: string, open: boolean = true) {
		this.helpboxOverlay = {
			title,
			content: description,
		};

		if (open) {
			this.helpOpen = true;
		}
	}

	public toggleHelp() {
		this.helpOpen = !this.helpOpen;
		this.helpboxOverlay = undefined;
	}
}
